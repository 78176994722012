const PATH = 'fe.components.learning_resources.learning_resources_header';

const messages = {
  deselect: {
    key: `${PATH}.deselect`,
    defaultValue: 'Deselect & Cancel'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  selected: {
    key: `${PATH}.selected`,
    defaultValue: 'Resources Selected'
  },
  recommend: {
    key: `${PATH}.recommend`,
    defaultValue: 'Recommend Selected'
  },
  resources: {
    key: `${PATH}.resources`,
    defaultValue: 'Recommend Resources'
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  },
  success: {
    key: `${PATH}.success`,
    defaultValue: 'Recommendations have been sent'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  },
  send: {
    key: `${PATH}.send`,
    defaultValue: 'Send Recommendation'
  },
  messageLimit: {
    key: `${PATH}.message_limit`,
    defaultValue: 'Message exceeds the 4,000 character limit.'
  },
  invalidMessage: {
    key: `${PATH}.invalid_message`,
    defaultValue: 'Invalid message'
  }
};

export default messages;
