import Title from './Title';
import Banner from './Banner';
import Header from '../Header';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Outlet } from 'react-router-dom';
import ShowResourcesModal from '../ShowResourcesModal';
import BodyModal from '../ShowResourcesModal/BodyModal';
import { toastError, toastSuccess } from 'components/Toast';
import { createRecommendations } from 'actions/recommendations';
import { useCallback, useContext, useEffect, useState } from 'react';
import SelectEducatorBodyModal from '../ShowResourcesModal/SelectEducatorBodyModal';
import { LearningResourcesContext } from 'pages/LearningResources/LearningResourcesContext';

type Recommendations = {
  author_id?: number;
  recipient_id: any;
  resourceable_id: number;
  resourceable_type: string;
};

interface LearningResourcesHeaderProps {
  userId?: number;
}

function LearningResourcesHeader({ userId }: LearningResourcesHeaderProps) {
  const [step, setStep] = useState(0);
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const context = useContext(LearningResourcesContext);

  if (!context) {
    throw new Error(
      'LearningResourcesHeader must be used within a Learning Resources Provider'
    );
  }

  const {
    recommendedVideosArray,
    setRecommendedVideosArray,
    selectedTeachersArray,
    setSelectedTeachersArray,
    setIsPending
  } = context;

  const validateLength = useCallback(
    (maxLength: number) => {
      return message.length <= maxLength;
    },
    [message]
  );

  const validateMessage = useCallback(() => {
    const maxLength = 4000;

    if (!validateLength(maxLength)) {
      setError(i18n.ft(messages.messageLimit));
      return false;
    }

    const sanitizedMessage = sanitizeMessage(message);

    if (detectSQLInjection(message) || !sanitizedMessage) {
      setError(i18n.ft(messages.invalidMessage));
      return false;
    }

    return true;
  }, [message, validateLength]);

  const createRecommendationsToSelectedUsers = useCallback(() => {
    if (validateMessage()) {
      setIsPending(true);
      const recommendations: Recommendations[] = [];
      selectedTeachersArray.forEach(teacher => {
        recommendedVideosArray.forEach(video => {
          recommendations.push({
            author_id: userId,
            recipient_id: teacher,
            resourceable_id: video.id,
            resourceable_type: 'learning_resource'
          });
        });
      });
      createRecommendations(recommendations)
        .then(() => {
          toastSuccess({ description: i18n.ft(messages.success) });
          setStep(0);
          setShowConfirmationModal(false);
          setRecommendedVideosArray([]);
          setSelectedTeachersArray([]);
          setIsPending(false);
          setMessage('');
        })
        .catch(error => {
          toastError(error);
          setStep(1);
          setSelectedTeachersArray([]);
          setIsPending(false);
        });
    }
  }, [
    validateMessage,
    setIsPending,
    selectedTeachersArray,
    recommendedVideosArray,
    userId,
    setRecommendedVideosArray,
    setSelectedTeachersArray
  ]);

  useEffect(() => {
    if (step === 2) {
      createRecommendationsToSelectedUsers();
      setStep(1);
    }
  }, [createRecommendationsToSelectedUsers, step, message]);

  useEffect(() => {
    if (recommendedVideosArray.length === 0) {
      setShowConfirmationModal(false);
    }
  }, [recommendedVideosArray]);

  function sanitizeMessage(message: string): boolean {
    const hasUnsafeCharacters = /<\/?[^>]+(>|$)/g.test(message);

    return !hasUnsafeCharacters;
  }

  function detectSQLInjection(message: string) {
    const sqlInjectionPattern =
      /\b(SELECT|INSERT|DELETE|UPDATE|DROP|ALTER|--|;|\/\*|\*\/)\b/i;
    return sqlInjectionPattern.test(message);
  }

  return (
    <>
      <ShowResourcesModal
        title={
          <Title
            step={step}
            setStep={setStep}
            recommendedVideosCount={recommendedVideosArray.length}
            setShowConfirmationModal={setShowConfirmationModal}
          />
        }
        message={
          step === 0 ? (
            <BodyModal />
          ) : (
            <SelectEducatorBodyModal
              message={message}
              setMessage={setMessage}
              error={error}
              setError={setError}
            />
          )
        }
        confirmButtonString={
          step === 0 ? i18n.ft(messages.next) : i18n.ft(messages.send)
        }
        isOpen={showConfirmationModal}
        onConfirm={() => setStep(prev => prev + 1)}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
        step={step}
      />

      {recommendedVideosArray.length > 0 && (
        <Banner
          recommendedVideosCount={recommendedVideosArray.length}
          setRecommendedVideosArray={setRecommendedVideosArray}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      )}
      <main
        className=" mx-auto my-10 px-4 2xl:px-0"
        style={{ maxWidth: '1360px' }}
      >
        <div className="px-4 md:px-0">
          <Header />
        </div>
        <Outlet />
      </main>
    </>
  );
}

export default LearningResourcesHeader;
