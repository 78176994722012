import i18n from 'helpers/i18n';
import { useState } from 'react';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Input } from 'semantic-ui-react';
import useCurrentUser from 'hooks/useCurrentUser';
import Important from 'components/LearningResources/Important';
import LastViewed from 'components/LearningResources/LastViewed';
import Recommended from 'components/LearningResources/Recommended';
import SearchResults from 'components/LearningResources/SearchResults';

function EducatorViewMode() {
  const currendUser = useCurrentUser();
  const [query, setQuery] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');

  function handleChange(e: any) {
    setCurrentQuery(e.target.value);
    if (e.target.value === '') {
      setQuery('');
    }
  }

  function isCoach() {
    return currendUser.roles.includes('lr_coach');
  }

  return (
    <>
      {isCoach() && (
        <div className="w-full mt-4 p-4 bg-[#cbe3ed] rounded">
          <div className="flex justify-between">
            <div>{i18n.ft(messages.educatorView)}</div>
            <div className="font-bold text-[#0A9CCA] cursor-pointer">
              <a href={RouteHelpers.getPath('coach-learning-resources')}>
                {`< ${i18n.ft(messages.back)}`}
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="mt-8 mb-16 px-4 md:px-0 md:py-8">
        <div className="w-full ">
          <Input
            fluid
            clearable="true"
            id="search"
            icon="search"
            className="mb-8 max-w-[856px] text-center mx-auto"
            placeholder={i18n.ft(messages.search)}
            onChange={handleChange}
            value={currentQuery}
            onKeyDown={(e: { key: string }) => {
              if (e.key === 'Enter') {
                setQuery(currentQuery);
              }
            }}
          />
        </div>

        {query === '' ? (
          <>
            <Recommended />
            <LastViewed />
            <Important />
          </>
        ) : (
          <SearchResults query={query} />
        )}
      </div>
    </>
  );
}

export default EducatorViewMode;
