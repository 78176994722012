import useCurrentUser from 'hooks/useCurrentUser';
import MyEducators from 'components/MyEducators';
import EducatorViewMode from 'components/MyEducators/EducatorViewMode';

function HomePage() {
  const user = useCurrentUser();

  if (user.roles.includes('lr_coach')) {
    return <MyEducators />;
  }

  return <EducatorViewMode />;
}

export default HomePage;
