import i18n from 'helpers/i18n';
import messages from './messages';
import Checkbox from './Checkbox';
import useCurrentUser from 'hooks/useCurrentUser';
import { getMyEducators } from 'actions/coachings';
import { Select, TextArea } from 'semantic-ui-react';
import searchIcon from 'images/learningResources/search.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import { LearningResourcesContext } from 'pages/LearningResources/LearningResourcesContext';
import Loader from './Loader';
import { toastError } from 'components/Toast';
import { createGlobalStyle } from 'styled-components';

export const SelectGlobalStyle = createGlobalStyle`
.ui.inline.dropdown > .text {
  font-weight: normal;
}

.ui.inline.dropdown .dropdown.icon {
  margin: -1em -0.65em 0em 0em;
  font-size: 1.1em;
}
`;

interface SelectEducatorBodyModalProps {
  error: string;
  message: string;
  setError: (error: string) => void;
  setMessage: (message: string) => void;
}

interface Teacher {
  key: string;
  text: string;
}

const recommendationSettings = [
  {
    key: '1',
    text: 'Only if the resource hasn’t been recommended previously',
    value: 'michelle'
  },
  {
    key: '2',
    text: 'Only if the resource hasn’t been recommended in the past 30 days',
    value: 'wei'
  },
  {
    key: '3',
    text: 'Recommend even if it has been recommended previously',
    value: 'kat'
  }
];

function SelectEducatorBodyModal({
  error,
  message,
  setError,
  setMessage
}: SelectEducatorBodyModalProps) {
  const { id: userId } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sendEmail, setSendEmail] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [educators, setEducators] = useState<Teacher[]>([]);
  const [selectedTeachers, setSelectedTeachers] = useState<string[]>([]);
  const [selectedRecommendationSetting, setSelectedRecommendationSetting] =
    useState('0');
  const context = useContext(LearningResourcesContext);

  if (!context) {
    throw new Error(
      'SelectEducatorBodyModal must be used within a Learning Resources Provider'
    );
  }

  const { setSelectedTeachersArray, isPending, setCanSubmit } = context;

  const filteredTeachers = educators.filter(educator =>
    educator.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    getMyEducators()
      .then(response => {
        const educatorOptions = response.data.coachings.map((educator: any) => {
          return {
            key: educator.id,
            text: educator.first_name + ' ' + educator.last_name
          };
        });
        educatorOptions.unshift({
          key: 'all',
          text: i18n.ft(messages.all)
        });
        setEducators(educatorOptions);
      })
      .catch(error => {
        toastError(error);
      });
  }, [userId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current !== event.target &&
        !buttonRef.current?.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (message.length === 0 && !sendEmail) {
      setCanSubmit(true);
    }
  }, [message.length, sendEmail, setCanSubmit]);

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  function handleCheckboxChange(value: string) {
    if (selectedTeachers.includes(value)) {
      if (value === 'all') {
        setSelectedTeachers([]);
        setSelectedTeachersArray([]);
        return;
      }
      const selectedTeachersArray = selectedTeachers.filter(
        item => item !== value
      );
      setSelectedTeachers(selectedTeachersArray);
      setSelectedTeachersArray(selectedTeachersArray);
    } else {
      if (value === 'all') {
        setSelectedTeachers([value]);
        setSelectedTeachersArray(
          educators.filter(educator => educator.key !== 'all').map(e => e.key)
        );
        return;
      }
      setSelectedTeachers([...selectedTeachers, value]);
      setSelectedTeachersArray([...selectedTeachers, value]);
    }
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  /***
   * We can submit when there is NO MESSAGE and the checkbox is NOT CHECKED
   * OR
   * when there is a MESSAGE and the checkbox is CHECKED
   ***/
  function handleCanSubmitWhenMessageChange(message: string) {
    if (message.trim().length > 0 && !sendEmail) {
      setCanSubmit(false);
    } else if (message.trim().length > 0 && sendEmail) {
      setCanSubmit(true);
    } else if (message.trim().length === 0 && !sendEmail) {
      setCanSubmit(true);
    } else if (message.trim().length === 0 && sendEmail) {
      setCanSubmit(false);
    }
  }

  function handleCanSubmitOnCheckboxChange(sendEmail: boolean) {
    if (message.length > 0 && !sendEmail) {
      setCanSubmit(false);
    } else if (message.length > 0 && sendEmail) {
      setCanSubmit(true);
    } else if (message.length === 0 && !sendEmail) {
      setCanSubmit(true);
    } else if (message.length === 0 && sendEmail) {
      setCanSubmit(false);
    }
  }
  /***/

  if (educators.length === 0 || isPending) {
    return <Loader />;
  }

  return (
    <div className="h-[484px] max-h-[482px] pb-4">
      <SelectGlobalStyle />
      <h2 className="text-lg font-semibold mb-2">{i18n.ft(messages.select)}</h2>
      <div className="relative">
        <button
          type="button"
          ref={buttonRef}
          className="w-full max-w-[530px] min-h-10 h-auto py-1 border rounded-md px-4 text-left flex justify-between items-center !border-[#85878A] focus:outline-none focus:!border-2 focus:!border-[#0A9CCA]"
          onClick={toggleDropdown}
        >
          {selectedTeachers.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {selectedTeachers.map(teacherKey => (
                <div
                  key={teacherKey}
                  className="bg-[#CEEBF4] rounded px-3 py-1 font-bold border border-[#85878A] focus:outline-none focus:!border-2 focus:!border-[#0A9CCA]"
                >
                  {
                    educators.find(educator => educator.key === teacherKey)
                      ?.text
                  }
                  <button
                    type="button"
                    className="ml-2 text-[#636568]"
                    onClick={e => {
                      e.stopPropagation();
                      handleCheckboxChange(teacherKey);
                    }}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <span className="text-gray-500">{i18n.ft(messages.teachers)}</span>
          )}
          <svg
            className={`h-5 w-5 ml-2 ${isOpen ? 'transform rotate-180' : ''}`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path fillRule="evenodd" d="M10 14l6-6H4l6 6z" />
          </svg>
        </button>

        {isOpen && (
          <div
            ref={dropdownRef}
            className="absolute top-full left-0 w-full max-w-[530px] bg-white border-2 rounded-md shadow-lg mt-1 border-[#0A9CCA] focus:outline-none focus:!border-2 focus:!border-[#0A9CCA]"
          >
            <div className="py-2 bg-white relative z-10">
              <div className="relative m-2 border rounded-md flex outline-none">
                <input
                  type="text"
                  className="w-full border-0 rounded-md p-2 outline-none"
                  placeholder={i18n.ft(messages.search)}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <img className="mr-2" alt="" src={searchIcon} />
              </div>
              {filteredTeachers.map(teacher => (
                <label
                  key={teacher.key}
                  className={`px-2 block cursor-pointer ${
                    selectedTeachers.includes(teacher.key) ? 'bg-[#CEEBF4]' : ''
                  }`}
                >
                  <Checkbox
                    checked={selectedTeachers.includes(teacher.key)}
                    onChange={() => handleCheckboxChange(teacher.key)}
                  />
                  <span className="ml-2">{teacher.text}</span>
                </label>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="pt-8">
        <h2 className="text-lg font-semibold mb-2">
          {i18n.ft(messages.recommend)}
        </h2>
        <Select
          placeholder={i18n.ft(messages.previously)}
          fluid
          selection
          options={recommendationSettings}
          onChange={(_, { value }) =>
            setSelectedRecommendationSetting(value?.toString() || '')
          }
          value={selectedRecommendationSetting}
          className="w-full max-w-[530px] inline border !border-[#85878A] focus:!border-2 focus:!border-[#0A9CCA]"
        />
      </div>

      <div className="pt-8">
        <h2 className="text-lg font-semibold mb-2">
          {i18n.ft(messages.message)}
        </h2>
        <TextArea
          className="w-full py-3 px-4 border border-[#85878A] rounded min-h-[122px] focus:outline-none focus:!border-2 focus:!border-[#0A9CCA]"
          placeholder={i18n.ft(messages.explanation)}
          value={message}
          onChange={(e: any, { value }: any) => {
            setError('');
            setMessage(value);
            handleCanSubmitWhenMessageChange(value);
          }}
        />
        <div className="text-sm text-red-500 text-right font-bold">{error}</div>
      </div>

      <label className={`mt-4 block cursor-pointer`}>
        <Checkbox
          checked={sendEmail}
          onChange={() => {
            setSendEmail(!sendEmail);
            handleCanSubmitOnCheckboxChange(!sendEmail);
          }}
        />
        <span className="ml-2">{i18n.ft(messages.email)}</span>
      </label>
    </div>
  );
}

export default SelectEducatorBodyModal;
